html, body {
	margin: 0;
	width: 100%;
	min-width: 100%;
	height: 100%;
	min-height: 100%;
}

#root {
  min-height: 100%;
}

.App {
  min-height: 100%;
  height: auto;
  min-width: 100%;

}
.mainContent {
  min-height: 92vh;
	height: 92vh;
}
.innerContent {
	height: 90%;
	width: 90%!important;
}
.topHeader {
	background-color: #262b36!important;
}
.leftMenu {
	/* background-color: #262b36!important; */
}
.contentDropZoneContainer {
	border: 3px dashed #24231c;
	background-color: 'grey';
	width: 100%;
	min-height: 100px;
	height: auto;
	padding:3px;
}
.contentCard {
	border: 1px solid #eeeeee
}
.all-copy {
  -webkit-user-select: all;  /* Chrome all / Safari all */
  -moz-user-select: all;     /* Firefox all */
  -ms-user-select: all;      /* IE 10+ */
  user-select: all;          /* Likely future */
}
.contentCard .channel {
	font-size: 12px;
	font-weight: bold;
	color: blue;
	margin-right: 5px;
	clear: both;
}
.contentCard .content-text {
	font-size: 14px;
	color: black;
}
button.link {
	border: none;
	background: none;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}
button.link.regular {
	font-weight: normal;
}


.stats-widget {
	display: inline-block;

}
.stats-widget-row {
	display: inline-block;

}
.stats-widget-cell {
	border: 1px solid #999999;
	display: inline-block;
	padding: 3px 10px;
	text-align: center;
}
/* .stats-widget-cell:nth-child(3n+1) {
	clear: left;
} */
.stats-widget-header {
	display: table-caption;
	background-color: #cccccc;
	padding: 5px;
	font-size: 10px;
	white-space: nowrap;
}
.positive {
	background-color: green;
	margin: 2px;
}
.negative {
	background-color: red;
	margin: 2px;
}
.neutral {
	background-color: yellow;
}